$colors: (
  'primary': var(--ion-color-primary),
  'primary-light': var(--ion-color-primary-light),
  'primary-ultra-light': var(--ion-color-primary-ultra-light),
  'grey-ultra-dark': var(--ultra-dark-grey),
  'grey-dark': var(--dark-grey),
  'grey': var(--medium-grey),
  'grey-light': var(--light-grey),
  'grey-ultra-light': var(--ultra-light-grey),
  'success': var(--ion-color-success),
  'success-light': var(--ion-color-success-light),
  'danger': var(--ion-color-danger),
  'danger-light': var(--ion-color-danger-light),
  'white': var(--white),
  'black': var(--black),
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }
}
