ion-toolbar {
  ion-title {
    font-size: 18px;
    text-align: center;
  }
}

.tabbed-list-container {
  .tabs {
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 1rem;

    ion-segment-button {
      --indicator-color: var(--ion-color-white-shade);
      --indicator-height: 4px;
      --color-checked: var(--ion-color-dark);
      --color: var(--ion-color-white-shade);

      &::part(indicator) {
        width: 60%;
        left: 20%;
      }

      &::part(indicator-background) {
        border-radius: 2px;
      }

      ion-label {
        letter-spacing: -0.53px;
      }
    }
  }

  .quotes-list {
    background-color: var(--ultra-light-grey);
    display: flex;
    flex-grow: 1;
    min-height: 0;
  }
}

.external-page {
  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 64px;

    .logo {
      max-width: 246px;
      max-height: 246px;
    }
  }

  .form {
    margin-top: 75px;

    ion-col {
      --max-height: 54px;
      --min-height: 0;
      padding: 0;
    }
  }
}

.external-page-footer {
  --ion-toolbar-background: var(--white);

  ion-toolbar {
    padding: 1rem;
    --ion-safe-area-bottom: 1rem;
    --padding-start: 0;
    --padding-end: 0;

    ion-button {
      width: 100%;
      height: 45px;
      text-transform: none;
      font-size: 18px;
      font-weight: 500;
    }
  }

  ion-buttons {
    display: flex;
    flex-direction: column !important;
  }
}

.icon-end {
  margin-right: 0;
  color: var(--icon-color);
}
