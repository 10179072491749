$layouts: grid, flex;
@each $layout in $layouts {
  .#{$layout} {
    display: $layout;
  }
}

.flex-none {
  flex: none;
}

$positions: relative, absolute, fixed, sticky;
@each $position in $positions {
  .#{$position} {
    position: $position;
  }
}

$flex-directions: row, row-reverse, column, column-reverse;
@each $direction in $flex-directions {
  .flex-#{$direction} {
    flex-direction: $direction;
  }
}

$gaps: 0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 48, 64, 80;
@each $gap in $gaps {
  .gap-#{$gap} {
    gap: #{calc($gap/16)}rem;
  }
}

@each $gap in $gaps {
  .column-gap-#{$gap} {
    & > * {
      margin-bottom: #{calc($gap/16)}rem;
    }
  }
  .row-gap-#{$gap} {
    & > * {
      margin-left: #{calc($gap/16)}rem;
    }
  }
}

$flex-grow: 0, 1;
@each $grow in $flex-grow {
  .flex-grow-#{$grow} {
    flex-grow: $grow;
  }
}

$flex-shrink: 0, 1;
@each $shrink in $flex-shrink {
  .flex-shrink-#{$shrink} {
    flex-shrink: $shrink;
  }
}

$item-alignments: start, center, end;
@each $alignment in $item-alignments {
  .align-items-#{$alignment} {
    align-items: $alignment;
  }
  .align-self-#{$alignment} {
    align-self: $alignment;
  }

  .justify-items-#{$alignment} {
    justify-items: $alignment;
  }
  .justify-self-#{$alignment} {
    justify-self: $alignment;
  }

  .place-items-#{$alignment} {
    place-items: $alignment;
  }
  .place-self-#{$alignment} {
    place-self: $alignment;
  }
}

$content-alignments: start, center, end, space-between, space-around,
  space-evenly;
@each $alignment in $content-alignments {
  .align-content-#{$alignment} {
    align-content: $alignment;
  }
  .justify-content-#{$alignment} {
    justify-content: $alignment;
  }
  .place-content-#{$alignment} {
    place-content: $alignment;
  }
}

$insets: 0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 48, 64, 80;
@each $inset in $insets {
  .inset-#{$inset} {
    top: #{calc($inset/16)}rem;
    right: #{calc($inset/16)}rem;
    bottom: #{calc($inset/16)}rem;
    left: #{calc($inset/16)}rem;
  }

  .x-#{$inset} {
    right: #{calc($inset/16)}rem;
    left: #{calc($inset/16)}rem;
  }

  .y-#{$inset} {
    top: #{calc($inset/16)}rem;
    bottom: #{calc($inset/16)}rem;
  }

  .t-#{$inset} {
    top: #{calc($inset/16)}rem;
  }
  .r-#{$inset} {
    right: #{calc($inset/16)}rem;
  }
  .b-#{$inset} {
    bottom: #{calc($inset/16)}rem;
  }
  .l-#{$inset} {
    left: #{calc($inset/16)}rem;
  }
}

.hidden {
  display: none;
}

.gap-wrapper {
  display: flex;
  --gap: 0px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);

  & > * {
    margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
  }

  & > *:first-child {
    margin-left: 0;
  }
}
