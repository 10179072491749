$spacings: 0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 48, 64, 80;

@each $spacing in $spacings {
  .p-#{$spacing} {
    padding: #{calc($spacing/16)}rem;
  }
  .px-#{$spacing} {
    padding-right: #{calc($spacing/16)}rem;
    padding-left: #{calc($spacing/16)}rem;
  }
  .py-#{$spacing} {
    padding-top: #{calc($spacing/16)}rem;
    padding-bottom: #{calc($spacing/16)}rem;
  }
  .pt-#{$spacing} {
    padding-top: #{calc($spacing/16)}rem;
  }
  .pb-#{$spacing} {
    padding-bottom: #{calc($spacing/16)}rem;
  }
  .pl-#{$spacing} {
    padding-left: #{calc($spacing/16)}rem;
  }
  .pr-#{$spacing} {
    padding-right: #{calc($spacing/16)}rem;
  }

  .m-#{$spacing} {
    margin: #{calc($spacing/16)}rem;
  }
  .mx-#{$spacing} {
    margin-right: #{calc($spacing/16)}rem;
    margin-left: #{calc($spacing/16)}rem;
  }
  .my-#{$spacing} {
    margin-top: #{calc($spacing/16)}rem;
    margin-bottom: #{calc($spacing/16)}rem;
  }
  .mt-#{$spacing} {
    margin-top: #{calc($spacing/16)}rem;
  }
  .mb-#{$spacing} {
    margin-bottom: #{calc($spacing/16)}rem;
  }
  .ml-#{$spacing} {
    margin-left: #{calc($spacing/16)}rem;
  }
  .mr-#{$spacing} {
    margin-right: #{calc($spacing/16)}rem;
  }

  .-m-#{$spacing} {
    margin: -#{calc($spacing/16)}rem;
  }
  .-mx-#{$spacing} {
    margin-right: -#{calc($spacing/16)}rem;
    margin-left: -#{calc($spacing/16)}rem;
  }
  .-my-#{$spacing} {
    margin-top: -#{calc($spacing/16)}rem;
    margin-bottom: -#{calc($spacing/16)}rem;
  }
  .-mt-#{$spacing} {
    margin-top: -#{calc($spacing/16)}rem;
  }
  .-mb-#{$spacing} {
    margin-bottom: -#{calc($spacing/16)}rem;
  }
  .-ml-#{$spacing} {
    margin-left: -#{calc($spacing/16)}rem;
  }
  .-mr-#{$spacing} {
    margin-right: -#{calc($spacing/16)}rem;
  }

  .w-#{$spacing} {
    width: #{calc($spacing/16)}rem;
  }

  .min-w-#{$spacing} {
    min-width: #{calc($spacing/16)}rem;
  }

  .max-w-#{$spacing} {
    max-width: #{calc($spacing/16)}rem;
  }

  .h-#{$spacing} {
    height: #{calc($spacing/16)}rem;
  }

  .min-h-#{$spacing} {
    min-height: #{calc($spacing/16)}rem;
  }

  .max-h-#{$spacing} {
    max-height: #{calc($spacing/16)}rem;
  }

  .border-radius-#{$spacing} {
    border-radius: #{calc($spacing/16)}rem;
  }
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.border-radius-full {
  border-radius: 100%;
}

.ion-flex {
  display: flex;
}

.ion-flex-grow {
  flex-grow: 1;
}

.ion-flex-column {
  display: flex;
  flex-direction: column;
}

ion-label.no-text-decoration {
  a {
    text-decoration: none;
  }
}

.sticky-button-container {
  position: sticky;
  bottom: 0;
  z-index: 200;
  padding: 16px;
  background-color: white;
}

.overflow-hidden {
  overflow: hidden;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
