.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

$fontSizes: (11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26);
@each $size in $fontSizes {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
}

$textAlignments: (left, center, right);
@each $alignment in $textAlignments {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

$textTransforms: (capitalize, uppercase, lowercase);
@each $transform in $textTransforms {
  .text-#{$transform} {
    text-transform: $transform;
  }
}
