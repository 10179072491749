:root {
  --ion-toolbar-background: var(--ion-color-primary);

  --ion-tab-bar-background: var(--ion-color-primary);
  --ion-tab-bar-background-focused: var(--ion-tab-bar-background);
  --ion-tab-bar-color: #ffffff;
  --ion-tab-bar-color-selected: var(--ion-tab-bar-color);

  --white: #fff;
  --black: #000;

  --ultra-dark-grey: #2d3642;
  --dark-grey: #666d75;
  --medium-grey: #9ba6b3;
  --light-grey: #d3dbe5;
  --ultra-light-grey: #f6f8fb;

  --ion-font-family: MavenPro;

  --icon-color: #909aa5;
}

* {
  font-weight: 500;
}

:root {
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #2d3642;
  --ion-color-white-contrast-rgb: 45, 54, 66;
  --ion-color-white-shade: #909aa5;
  --ion-color-white-tint: #ffffff;

  --ion-color-inactive: #d3dbe5ff;
  --ion-color-inactive-rgb: 211, 219, 229;
  --ion-color-inactive-contrast: #666d75ff;
  --ion-color-inactive-contrast-rgb: 102, 109, 117;
  --ion-color-inactive-shade: #c1cae0;
  --ion-color-inactive-tint: #dfe8ff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-inactive {
  --ion-color-base: var(--ion-color-inactive) !important;
  --ion-color-base-rgb: var(--ion-color-inactive-rgb) !important;
  --ion-color-contrast: var(--ion-color-inactive-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-inactive-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-inactive-shade) !important;
  --ion-color-tint: var(--ion-color-inactive-tint) !important;
}
