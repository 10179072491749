@font-face {
  font-family: 'MavenPro';
  src: url('../assets/fonts/MavenPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MavenPro';
  src: url('../assets/fonts/MavenPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MavenPro';
  src: url('../assets/fonts/MavenPro-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
